import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/tmp/node_modules/@nib/gatsby-theme-mesh-docs/src/templates/Content.js";
import Tag from '@nib/tag';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`If you have a suggestion for a new component or require support, please reach out on `}<a parentName="p" {...{
        "href": "https://nibit.slack.com/channels/mesh"
      }}>{`Slack`}</a>{`, `}<a parentName="p" {...{
        "href": "https://nibgroup.workplace.com/groups/1079231549122810/"
      }}>{`Workplace`}</a>{` or by `}<a parentName="p" {...{
        "href": "mailto:mailto:designops@nib.com.au"
      }}>{`email`}</a>{`.`}</p>
    <p>{`For more information about what the DesignOps team are working on right now, `}<a parentName="p" {...{
        "href": "/team/roadmap/"
      }}>{`see the roadmap`}</a>{`.`}</p>
    <h2 {...{
      "id": "status",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#status",
        "aria-label": "status permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`Status`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Component`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`React`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`CSS Framework`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Figma`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Types`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Accordion`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released in v7.5.0`}</Tag></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Alert`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released in v3.6.1`}</Tag></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Autocomplete`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="light" size="small" mdxType="Tag">{`Not available`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="light" size="small" mdxType="Tag">{`Not available`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="light" size="small" mdxType="Tag">{`Not available`}</Tag></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Button`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released in v9.8.0`}</Tag></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Box`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="light" size="small" mdxType="Tag">{`Not available`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="light" size="small" mdxType="Tag">{`Not available`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released in v1.11.0`}</Tag></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Card`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released in v1.3.0`}</Tag></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Checkbox`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released in v4.4.0`}</Tag></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Checkbox Group`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="light" size="small" mdxType="Tag">{`Not available`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released in v3.5.0`}</Tag></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Colors`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="error" size="small" mdxType="Tag">{`Deprecated`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Column`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="light" size="small" mdxType="Tag">{`Not available`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="light" size="small" mdxType="Tag">{`Not available`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released in v1.11.0`}</Tag></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Columns`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="light" size="small" mdxType="Tag">{`Not available`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="light" size="small" mdxType="Tag">{`Not available`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released in v1.11.0`}</Tag></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Container`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="light" size="small" mdxType="Tag">{`Not available`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released in v4.3.0`}</Tag></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Copy`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released in v3.5.0`}</Tag></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Data Table`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="warning" size="small" mdxType="Tag">{`Parked`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="light" size="small" mdxType="Tag">{`Not available`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="light" size="small" mdxType="Tag">{`Not available`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="light" size="small" mdxType="Tag">{`Not available`}</Tag></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Date Picker`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="light" size="small" mdxType="Tag">{`Not available`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="light" size="small" mdxType="Tag">{`Not available`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released in v3.8.0`}</Tag></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Date Textbox`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="light" size="small" mdxType="Tag">{`Not available`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released in v5.3.0`}</Tag></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Divider`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released in v4.2.0`}</Tag></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Error Template`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="light" size="small" mdxType="Tag">{`Not available`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released in v7.6.0`}</Tag></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Expander`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="light" size="small" mdxType="Tag">{`Not available`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="light" size="small" mdxType="Tag">{`Not available`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Release in v1.3.0`}</Tag></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Expander Group`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="light" size="small" mdxType="Tag">{`Not available`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="light" size="small" mdxType="Tag">{`Not available`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released in v2.3.0`}</Tag></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Feature Panel`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released in v6.3.0`}</Tag></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Footer`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="light" size="small" mdxType="Tag">{`Not available`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released in v8.11.0`}</Tag></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Form`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Release in v5.5.0`}</Tag></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Form Control`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Release in v4.3.0`}</Tag></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Grow Layout`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="light" size="small" mdxType="Tag">{`Not available`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="light" size="small" mdxType="Tag">{`Not available`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released in v3.3.0`}</Tag></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Header`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="light" size="small" mdxType="Tag">{`Not available`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released in v8.12.0`}</Tag></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Header Footer Layout`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="light" size="small" mdxType="Tag">{`Not available`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Release in v9.13.0`}</Tag></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Heading`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released in v5.4.0`}</Tag></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Hero Panel`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released in v4.3.0`}</Tag></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Hidden`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="light" size="small" mdxType="Tag">{`Not available`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="light" size="small" mdxType="Tag">{`Not available`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released in v1.11.0`}</Tag></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Icons`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="light" size="small" mdxType="Tag">{`Not available`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released in v2.10.0`}</Tag></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Info Box`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="light" size="small" mdxType="Tag">{`Not available`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="light" size="small" mdxType="Tag">{`Not available`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released in v1.1.0`}</Tag></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Inline`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="light" size="small" mdxType="Tag">{`Not available`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="light" size="small" mdxType="Tag">{`Not available`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released in v1.11.0`}</Tag></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Link`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released in v5.3.0`}</Tag></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Loader`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="light" size="small" mdxType="Tag">{`Not available`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released in v0.4.0`}</Tag></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Logo`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="light" size="small" mdxType="Tag">{`Not available`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released in v4.7.0`}</Tag></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Modal`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="light" size="small" mdxType="Tag">{`Not available`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released in v9.8.0`}</Tag></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Overlay`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="light" size="small" mdxType="Tag">{`Not available`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="light" size="small" mdxType="Tag">{`Not available`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released in v4.3.0`}</Tag></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Password Textbox`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="light" size="small" mdxType="Tag">{`Not available`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released in v3.3.0`}</Tag></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Product Badge`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="light" size="small" mdxType="Tag">{`Not available`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released in v1.4.0`}</Tag></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Radio`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released in v4.4.0`}</Tag></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Radio Button`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="light" size="small" mdxType="Tag">{`Not available`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released in v9.1.0`}</Tag></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Radio Group`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="light" size="small" mdxType="Tag">{`Not available`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released in v5.4.0`}</Tag></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`React HTML`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="light" size="small" mdxType="Tag">{`Not available`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="light" size="small" mdxType="Tag">{`Not available`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="light" size="small" mdxType="Tag">{`Not available`}</Tag></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Section`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="light" size="small" mdxType="Tag">{`Not available`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released in v4.3.0`}</Tag></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Select`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released in v4.6.0`}</Tag></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Simple Table`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released in v1.2.0`}</Tag></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Skip Link`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="light" size="small" mdxType="Tag">{`Not available`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="light" size="small" mdxType="Tag">{`Not available`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released in v3.5.0`}</Tag></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Small Footer`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="light" size="small" mdxType="Tag">{`Not available`}</Tag></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Small Header`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="light" size="small" mdxType="Tag">{`Not available`}</Tag></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Small Header Footer Layout`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="light" size="small" mdxType="Tag">{`Not available`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="light" size="small" mdxType="Tag">{`Not available`}</Tag></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Stack`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="light" size="small" mdxType="Tag">{`Not available`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="light" size="small" mdxType="Tag">{`Not available`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released in v1.11.0`}</Tag></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Tabs`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released in v4.5.0`}</Tag></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Tag`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="light" size="small" mdxType="Tag">{`Not available`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released in v1.1.0`}</Tag></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Textarea`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released in v3.5.0`}</Tag></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Textbox`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released in v4.7.0`}</Tag></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Tiles`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="light" size="small" mdxType="Tag">{`Not available`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="light" size="small" mdxType="Tag">{`Not available`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released in v1.11.0`}</Tag></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Toggle Input`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released in v3.10.0`}</Tag></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Tooltip`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Not available`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released in v0.1.0`}</Tag></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Theme`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="light" size="small" mdxType="Tag">{`Not available`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released in v5.6.0`}</Tag></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Villain Panel`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="light" size="small" mdxType="Tag">{`Not available`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released in v3.5.0`}</Tag></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Utility Button`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released in v2.2.0`}</Tag></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Product Card`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="light" size="small" mdxType="Tag">{`Not available`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released in v4.0.0`}</Tag></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Masked Textbox`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="light" size="small" mdxType="Tag">{`Not available`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released`}</Tag></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><Tag type="success" size="small" mdxType="Tag">{`Released in v5.3.0`}</Tag></td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      